import VisibilityIcon from '@mui/icons-material/Visibility';
import { Typography, IconButton, Box, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';

const ImagesCard = (props) => {
    const { communication } = props;

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <Typography variant="h6" component="h6" >
                    Imagenes
                </Typography>
                <Typography variant="body1" component="p" sx={{ paddingBottom: '1em', textAlign: 'justify' }}>
                    Aquí se muestran toda las imagenes subidas con la denuncia.
                </Typography>
            </Box>
            <Box sx={{paddingTop: '1em'}}>
                {communication.uploadedFiles.length > 0 ? (
                    <ImageList cols={3}>
                        {communication.uploadedFiles.map((image) => {
                            return (
                                <ImageListItem key={image.name}>
                                    <img
                                        alt={image.name}
                                        src={`${image.data}`}
                                        srcSet={`${image.data}`}
                                    />
                                    <ImageListItemBar
                                        title={image.name}
                                        actionIcon={
                                            <IconButton
                                                color='background1'
                                                onClick={() => {
                                                    var imageNewTab = new Image();
                                                    imageNewTab.src = image.data
                                                    imageNewTab.alt = image.name
                                                    var w = window.open('');
                                                    w.document.write(imageNewTab.outerHTML);
                                                }}
                                            >
                                                <VisibilityIcon />
                                            </IconButton>
                                        }
                                    />
                                </ImageListItem>
                            )
                        })}
                    </ImageList>
                ) : 'No se pueden cargar las imagenes'}
            </Box>
        </>
    );
}

export default ImagesCard;
