import { Button, Card, CardActions, CardContent, Typography } from "@mui/material";

const LinkCard = (props) => {
  const { id, title, description, to, buttonText } = props;
  //Card que contiene los datos de los enlaces internos principales de la página

  return (
    <Card key={id} sx={{ maxWidth: 450, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardContent>
        <Typography variant='h5' component='h5'>
          {title}
        </Typography>
        <Typography variant="body1" component="p">
          {description}
        </Typography>
      </CardContent>
      <CardActions sx={{ marginTop: 'auto', alignSelf: 'flex-end' }}>
        <Button size="small" href={to}>{buttonText}</Button>
      </CardActions>
    </Card>
  );

}

export default LinkCard;
