import { useState } from "react";
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button } from '@mui/material';
import { apiBaseUrl, apiToken } from "../../config/backend";


// Definir el componente ConnectionCard
const FinishCard = (props) => {
    // Extraer la propiedad 'communication' del objeto 'props'
    const { communication } = props;
    // Extraer las conexiones de 'communication'
    const [responses,setResponsesList] = useState(communication.responses);
    const [communicationStatus,setStatus] = useState(communication.status_id);

    const descargaRespuestaPdf = (file) => {
        const fetchData = async () => {
            var url = apiBaseUrl + 'communications/download/'+file.id;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
            });
            const dataResponse = await response.json();
            return dataResponse;
        }
        fetchData()
            .then((response)=>{
                const downloadUrl = `${response.payload.file}`;
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = 'adjunto.pdf';
                link.click();
            })
            .catch(() => {
                console.log('Error descargando archivo')
            })
        
    }
    // Renderizar el contenido del componente
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <Typography variant="h6" component="h6" >
                    Resolución del proceso de denuncia.
                </Typography>
            </Box>
            <Box sx={{ paddingTop: '2em' }}>
            {communicationStatus !== 3 ? (
                <Typography variant="body1" component="p" sx={{ paddingBottom: '1em', textAlign: 'justify' }}>
                    Denuncia pendiente de resolución.
                </Typography>
            ):(
                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Resolución</TableCell>
                            <TableCell>Creado por</TableCell>
                            <TableCell>Fecha</TableCell>
                            <TableCell>Adjuntos</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {responses.length > 0 && responses.reverse().map((response) => (
                            <TableRow
                                key={response.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{response.response}</TableCell>
                                <TableCell>{response.created_by}</TableCell>
                                <TableCell>{new Date(response.created_at).toLocaleString()}</TableCell>
                                <TableCell>
                                {response.response_files.length > 0 && 
                                    response.response_files.map((file) => (
                                        <Box>
                                        <Button
                                            startIcon={<PdfIcon />}
                                            sx={{ color: 'white', ml: '2em' }}
                                            onClick={() => descargaRespuestaPdf(file)}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Descargar
                                        </Button>
                                        </Box>
                                    )
                                )}
                                </TableCell>
                            </TableRow>
                        )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            
            )}
           </Box>     
        </>
    );
}

// Exportar el componente ConnectionCard por defecto
export default FinishCard;
