import { Card, CardContent, Typography } from "@mui/material";

const QuestionCard = (props) => {
  const { id, title, text } = props;
  //Componente Card de Material mui donde se carga el mapeo de datos del api response /faqs
  return (
    <Card key={id} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Typography variant="h6" component="h6" sx={{ fontSize: '1.1rem', paddingBottom: '0.5em' }}>
          {title}
        </Typography>
        <Typography variant="body1" component="p" color="text.secondary" sx={{ fontSize: '0.9rem', textAlign: 'justify' }} style={{ marginTop: 'auto' }}>
          {text}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default QuestionCard;
