import React, { useEffect, useState } from "react";
import { Box, LinearProgress, Typography } from "@mui/material";
import { HeadProvider, Title } from "react-head";
import parse from 'react-html-parser';

import useFetch from "../hooks/useFetch";

const PrivacyPolicy = () => {
    const { data, loading, errorFetch } = useFetch('fetchLatestPrivacyPolicy'); // Utilizamos el hook useFetch para obtener los datos de la política de privacidad
    const [content, setContent] = useState(null); // Estado para almacenar el contenido de la política de privacidad

    useEffect(() => {
        if (data && data.payload && data.payload.privacyPolicyText) {
            setContent(data.payload.privacyPolicyText); // Actualizamos el estado con el contenido de la política de privacidad obtenido de los datos
        }
    }, [data]);


    return (
        <>
            <HeadProvider>
                <Title>Politicas de privacidad</Title>
            </HeadProvider>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingBottom: '2em' }}>
                <Typography variant='h4' component='h4' >
                    Politicas de privacidad
                </Typography>
                <Typography variant="body1" component="p">
                    Aquí puede consultar las politicas de privacidad del canal de denuncias de grupo Dimoba
                </Typography>
            </Box>
            <Box>
                {loading && <LinearProgress />} {/* Mostramos una barra de progreso si la carga está en curso */}

                {
                    errorFetch ? ( // Si ocurrió un error en la carga de datos, mostramos un mensaje de error
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingTop: '2em' }}>
                            <Typography variant="body1" component="p">
                                Ha ocurrido un error en la carga de datos, pruebe más tarde.
                            </Typography>
                        </Box>
                    ) : (
                        <Typography variant="body1" component="div">
                            {content && parse(content)} {/* Si hay contenido, lo renderizamos utilizando la función parse para convertir el HTML en elementos React */}
                        </Typography>
                    )
                }
            </Box>
        </>
    );
};

export default PrivacyPolicy;
