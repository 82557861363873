import { useState, useEffect } from "react";
import { HeadProvider, Title } from "react-head";
import { LoadingButton } from "@mui/lab";
import { Box, Card, Grid, Snackbar, TextField, Typography } from "@mui/material";
import { Field, Form, Formik, useFormikContext } from "formik";

import { apiBaseUrl, apiToken } from "../config/backend";
import ShowIncidentCard from '../components/showIncident/ShowIncidentCard'

// Componente principal
const ShowIncident = () => {
    const [authenticated, setAuthenticated] = useState(false); // Estado para controlar la autenticación
    const [communication, setCommunication] = useState(null); // Estado para almacenar la comunicación
    const [errorLogin, setErrorLogin] = useState(false); // Estado para controlar el error de inicio de sesión
    const [errorMessage, setErrorMessage] = useState(null)
    const [loadingLogin, setLoadingLogin] = useState(false)

    const [ipAddress, setIpAddress] = useState('no detectado');
    const getIp = async () => {
        const response = await fetch('https://api.ipify.org/?format=json');
        return await response.json();
    };
    useEffect(() => {
        getIp().then(response => {
            if (response.ip) {
                setIpAddress(response.ip)
            }
        })
    }, [])

    // Manejador del envío del formulario
    const handleSubmit = async (values) => {
        setErrorMessage(null)
        setLoadingLogin(true)
        var url = apiBaseUrl + 'communications/' + values.communication_token;

        const jsonData = {
            "access_key": values.access_key,
            "ip_address": ipAddress,
        };
        const fetchData = async () => {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + apiToken,
                },
                body: JSON.stringify(jsonData)
            });
            switch (response.status) {
                case 200:
                    const data = await response.json();
                    setCommunication(data.payload.communication)
                    setAuthenticated(true);
                    break
                case 404:
                    setErrorLogin(true);
                    setErrorMessage('Código de denuncia desconocido')
                    break
                case 422:
                    setErrorLogin(true)
                    setErrorMessage('Contraseña incorrecta')
                    break
                default:
                    setErrorLogin(true)
                    setErrorMessage('Ha ocurrido un error en la carga de datos, pruebe más tarde.')
            }
            setLoadingLogin(false)
        };
        fetchData()
            .catch(() => {
                setErrorLogin(true)
                setErrorMessage('Ha ocurrido un error en la carga de datos, pruebe más tarde.')
                setLoadingLogin(false)
            })
    };

    // Componente del formulario de inicio de sesión
    const LoginForm = ({ form }) => {
        const { getFieldProps } = useFormikContext();

        return (
            <Form>
                <Grid container justifyContent="center">
                    <Box sx={{ width: '60%', marginTop: '3em' }}>
                        <Field name="communication_token">
                            {({ field }) => (
                                <Box sx={{ marginBottom: "1rem", textAlign: 'center', }}>
                                    <TextField
                                        disabled={loadingLogin}
                                        {...getFieldProps('communication_token')}
                                        {...field}
                                        label="Código de denuncia"
                                        style={{ width: '100%' }}
                                        error={form.touched.communication_token && Boolean(form.errors.communication_token)}
                                        helperText={form.touched.communication_token && form.errors.communication_token}
                                    />
                                </Box>
                            )}
                        </Field>
                        <Field name="access_key">
                            {({ field }) => (
                                <Box sx={{ marginBottom: "1rem", textAlign: 'center', }}>
                                    <TextField
                                        disabled={loadingLogin}
                                        {...getFieldProps('access_key')}
                                        {...field}
                                        style={{ width: '100%' }}
                                        type="password"
                                        label="Contraseña"
                                        error={form.touched.access_key && Boolean(form.errors.access_key)}
                                        helperText={form.touched.access_key && form.errors.access_key}
                                    />
                                </Box>
                            )}
                        </Field>
                        <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '3em' }}>
                            <LoadingButton variant="contained" type="submit" loading={loadingLogin}>
                                Acceder
                            </LoadingButton>
                        </Box>
                    </Box>
                </Grid>
            </Form>
        );
    }

    return (
        <>
            {errorLogin &&
                <Snackbar
                    open={errorLogin}
                    autoHideDuration={3000}
                    onClose={() => {
                        setErrorLogin(false)
                    }}
                    message={errorMessage}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    style={{ paddingBottom: '2em' }}
                />
            }

            <HeadProvider>
                <Title>Canal de denuncias</Title>
            </HeadProvider>
            <Box>
                {authenticated && Object.keys(communication).length !== 0 ? ( // Si está autenticado y hay una comunicación disponible
                    <>
                        <ShowIncidentCard
                            communication={communication}
                        >
                        </ShowIncidentCard>
                    </>
                ) : ( // Si no está autenticado o no hay comunicación disponible
                    <>
                        <Box style={{ display: 'flex', justifyContent: 'right', paddingTop: '1em', paddingRight: '2em' }}>
                            <Card sx={{ width: '100%', height: '100%', minHeight: '650px' }}>
                                <Box style={{ justifyContent: 'center', paddingBottom: '1em', paddingTop: '2em', paddingRight: '2em', paddingLeft: '2em' }}>
                                    <Typography variant="h5" component="h5" sx={{ textAlign: 'center', paddingBottom: '1em' }}>
                                        Acceso a su denuncia
                                    </Typography>
                                    <Typography variant="body1" component="p" sx={{ textAlign: 'center' }}>
                                        Para acceder a su denuncia introduzca el código de denuncia que se le facilitó en el momento de su creación y la contraseña que se le asignó.
                                    </Typography>
                                </Box>
                                <Box style={{ justifyContent: 'center', paddingBottom: '1em', paddingTop: '2em', paddingRight: '2em', paddingLeft: '2em' }}>
                                    <Formik
                                        initialValues={{
                                            communication_token: "",
                                            access_key: "",
                                        }}
                                        onSubmit={handleSubmit}
                                    >
                                        {(props) => <LoginForm form={props} />}
                                    </Formik>
                                </Box>
                            </Card>
                        </Box>
                    </>
                )}
            </Box >
        </>
    );
};

export default ShowIncident;
