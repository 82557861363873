import { createBrowserRouter } from 'react-router-dom'

import Faq from './pages/Faq'
import Home from './pages/Home'
import Incident from './pages/CreateIncident'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Root from './components/Root'
import ShowIncident from './pages/ShowIncident'
import Terms from './pages/TermsOfUse'

// Crear un enrutador de navegador utilizando la función createBrowserRouter de react-router-dom
const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />, // Componente raíz asociado a la ruta '/'
        children: [
            {
                path: '/',
                element: <Home />, // Componente Home asociado a la ruta '/'
            },
            {
                path: '/faq',
                element: <Faq />, // Componente Faq asociado a la ruta '/faq'
            },
            {
                path: '/crear-incidencia',
                element: <Incident />, // Componente Incident asociado a la ruta '/crear-incidencia'
            },
            {
                path: '/consultar-incidencia',
                element: <ShowIncident />, // Componente ShowIncident asociado a la ruta '/consultar-incidencia'
            },
            {
                path: '/politica-de-privacidad',
                element: <PrivacyPolicy />, // Componente PrivacyPolicy asociado a la ruta '/politica-de-privacidad'
            },
            {
                path: '/terminos-de-uso',
                element: <Terms />, // Componente Terms asociado a la ruta '/terminos-de-uso'
            },
        ],
    },
])

export default router
