import { Children, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Box, Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, LinearProgress, Radio, RadioGroup, Snackbar, Step, StepLabel, Stepper, TextField, TextareaAutosize, Typography } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { HeadProvider, Title } from "react-head";
import * as yup from 'yup';

import { apiBaseUrl, apiToken } from "../config/backend";
import DataAccordion from "../components/createIncident/DataAccordion";
import useFetch from "../hooks/useFetch";

const CreateIncident = () => {
    const stepInfo = [
        {
            id: 0,
            subtitle: 'Tipificación denuncia',
            description: 'Para facilitar la posterior gestión de su denuncia, ayúdenos a categorizarla lo mejor posible.'
        },
        {
            id: 1,
            subtitle: 'Información personal',
            description: 'Si prefiere no indicar sus datos personales, puede hacerlo indicando que quiere que la denuncia sea anónima.'
        },
        {
            id: 2,
            subtitle: 'Información de detalle',
            description: 'Describa con el máximo detalle el hecho que desea denunciar, detallando la máxima información y adjuntando, si las hay, pruebas.'
        },
        {
            id: 3,
            subtitle: 'Finalizar',
            description: 'Como último paso del proceso debe indicar una clave secreta que solo ud. conocerá. Esta clave, junto al código de identificación que le indicaremos al finalizar serán los únicos medios para poder seguir la evolución de su comunicación.'
        },
    ];

    const initialValues = {
        // Step1
        company: null,
        category: null,
        suspect: null,
        acceptTermsOfUse: false,

        // Step2
        relationship: null,
        is_anonymous: '0',
        name: "",
        email: "",
        phone: "",

        // Step3
        notifiedManagment: '',
        complaint_subject_person: "",
        witness: "",
        location: "",
        incident: '',
        files: [],

        //step4
        access_key: '',
        confirmPassword: '',
        acceptDataProtection: false,
    }

    const [stepCont, setStepCont] = useState(0);
    const [snapshot, setSnapshot] = useState(initialValues);
    const [companies, setCompanies] = useState([]);
    const [relationships, setRelationships] = useState([]);
    const [categories, setCategories] = useState([]);
    const [communicationToken, setCommunicationToken] = useState(null);
    const [submittingForm, setSubmittingForm] = useState(false);
    const [errorCom, setErrorCom] = useState(false);

    const { data, loading, errorFetch } = useFetch('fetchConfiguration');

    useEffect(() => {
        if (data && data.payload) {
            if (data.payload.company) {
                setCompanies(data.payload.company);
            }
            if (data.payload.relationship) {
                setRelationships(data.payload.relationship);
            }
            if (data.payload.category) {
                setCategories(data.payload.category);
            }
        }
    }, [data]);

    const Wizard = ({ children, onSubmit }) => {
        const steps = Children.toArray(children);

        const step = steps[stepCont]; // Paso actual
        const totalSteps = steps.length; // Número total de pasos
        const isLastStep = stepCont === totalSteps - 1; // Indica si es el último paso

        const next = values => {
            setSnapshot(values); // Guarda los valores del formulario actual
            setStepCont(Math.min(stepCont + 1, totalSteps - 1)); // Avanza al siguiente paso
        };

        const previous = values => {
            setSnapshot(values); // Guarda los valores del formulario actual
            setStepCont(Math.max(stepCont - 1, 0)); // Retrocede al paso anterior
        };

        const handleSubmit = async (values, bag) => {
            if (step.props.onSubmit) {
                await step.props.onSubmit(values, bag); // Realiza la validación y acciones específicas del paso actual
            }
            if (isLastStep) {
                next(values); // Avanza al siguiente paso
                return onSubmit(values, bag); // Ejecuta la función de envío final del formulario
            } else {
                bag.setTouched({}); // Marca todos los campos como "touched" para mostrar los mensajes de validación
                next(values); // Avanza al siguiente paso
            }
        };

        return (
            <Formik
                initialValues={snapshot}
                onSubmit={handleSubmit}
                validationSchema={step.props.validationSchema}
            >
                {formik => (
                    <Form>
                        {step}
                        {stepCont !== totalSteps && (
                            <Box style={{ display: 'flex', justifyContent: 'right', paddingTop: '1em', paddingRight: '2em' }}>
                                {stepCont > 0 && (
                                    <Button
                                        variant="contained"
                                        onClick={() => previous(formik.values)}
                                        style={{ marginRight: '1em' }}
                                        disabled={submittingForm}
                                    >
                                        Atras
                                    </Button>
                                )}
                                <Box>
                                    <LoadingButton
                                        variant="contained"
                                        type="submit"
                                        loading={submittingForm}
                                        disabled={submittingForm}
                                    >
                                        {isLastStep ? 'Terminar' : 'Siguiente'}
                                    </LoadingButton>
                                </Box>
                            </Box>
                        )}
                    </Form>
                )}
            </Formik>
        );
    };

    const WizardStep = ({ children }) => children;

    const handleOnSubmit = async (values) => {
        setSubmittingForm(true);
        let formData = new FormData()
        const files = values.files
        for (let i = 0; i < files.length; i++) {
            formData.append('files[]', files[i]);
        }
        formData.append("company_id", values.company.id.toString())
        formData.append("category_id", values.category.id.toString())
        formData.append("suspect_id", values.suspect.id.toString())
        formData.append("is_anonymous", values.is_anonymous)
        formData.append("company_relation_id", values.relationship.id.toString())
        formData.append("complainant_name", values.name)
        formData.append("complainant_email", values.email)
        formData.append("complainant_phone", values.phone ? values.phone : '')
        formData.append("commented", values.notifiedManagment)
        formData.append("happend", values.location ? values.location : '')
        formData.append("complaint_subject_person", values.complaint_subject_person)
        formData.append("witness", values.witness)
        formData.append("description", values.incident)
        formData.append("access_key", values.access_key)

        var url = apiBaseUrl + 'communications';

        const submitForm = async () => {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
                body: formData,
            });
            const dataResponse = await response.json();
            setCommunicationToken(dataResponse.payload.communication.communication_token);

            if (dataResponse.success !== true) {
                setErrorCom(true);
            }
        };

        submitForm()
            .catch(() => setErrorCom(true))
            .finally(() => setSubmittingForm(false));
    };


    return (
        <>
            {errorCom &&
                <Snackbar
                    open={errorCom}
                    autoHideDuration={3000}
                    onClose={() => {
                        setErrorCom(false)
                    }}
                    message='Ha ocurrido un enviando el formulario, pruebe más tarde.'
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    style={{ paddingBottom: '2em' }}
                />
            }

            <HeadProvider>
                <Title>Canal de denuncias</Title>
            </HeadProvider>

            {communicationToken ? (
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingBottom: '2em' }}>
                        <Typography variant="h4" component="h4">
                            Denuncia creada
                        </Typography>
                    </Box>
                    <Card sx={{ width: '100%', height: '100%' }}>
                        <CardContent>
                            <Box sx={{ justifyContent: 'center', paddingLeft: '2em', paddingRight: '2em' }}>
                                {/* Mensaje de éxito */}
                                <Typography variant="body1" component="p" sx={{ textAlign: 'justify' }}>
                                    Información enviada Gracias por utilizar la plataforma de denuncias. Su denuncia ha sido comunicada y en breve recibirá información.
                                </Typography>

                                <Typography variant="body1" component="p" sx={{ paddingBottom: '2em', textAlign: 'justify' }}>
                                    {/* Código de denuncia */}
                                    Anote el código de denuncia para poder consultar la evolución de la misma:
                                </Typography>
                                <Typography variant="h6" component="h6" sx={{ paddingBottom: '2em', textAlign: 'center' }}>
                                    Codigo de denuncia: {communicationToken}
                                </Typography>
                                <Typography variant="body1" component="p">
                                    {/* Instrucciones adicionales */}
                                    Con la clave que ha generado en el paso anterior y este código, se puede conectar en cualquier momento al portal de denuncias en "Consultar estado de incidencia" y consultar el estado de la misma.
                                </Typography>

                                <Typography variant="body1" component="p">
                                    {/* Información adicional */}
                                    Para garantizar la privacidad de la información, desde la plataforma no se le informará de nada, debe de ser ud. el que se conecte y compruebe la información.
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </>
            ) : (
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingBottom: '2em' }}>
                        <Typography variant="h4" component="h4">
                            Formulario para crear una denuncia
                        </Typography>
                    </Box>

                    {loading && <LinearProgress />}

                    {errorFetch ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingTop: '2em' }}>
                            <Typography variant="body1" component="p">
                                Ha ocurrido un error en la carga de datos, pruebe más tarde.
                            </Typography>
                        </Box>
                    ) : (
                        <>
                            {!loading &&
                                <>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%' }}>
                                        <Card sx={{ width: '100%', height: '100%' }}>
                                            <Stepper activeStep={stepCont} nonLinear sx={{ paddingTop: '2em', paddingLeft: '2em', paddingRight: '2em' }}>
                                                {stepInfo.map((info) => (
                                                    <Step key={info.id}>
                                                        <StepLabel sx={{ width: '100%' }}>{info.subtitle}</StepLabel>
                                                    </Step>
                                                ))}
                                            </Stepper>

                                            <CardContent>
                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Typography variant="body1" component="div">
                                                        {stepInfo[stepCont].description}
                                                    </Typography>
                                                </Box>

                                                <Box sx={{ paddingTop: '2em' }}>
                                                    <Wizard
                                                        onSubmit={handleOnSubmit}
                                                    >
                                                        {/* Step 1 */}
                                                        <WizardStep
                                                            validationSchema={yup.object({
                                                                company: yup.object().required('Seleccione una empresa'),
                                                                category: yup.object().required('Seleccione una categoria'),
                                                                suspect: yup.object().required('Seleccione un sospechoso'),
                                                            })}
                                                        >
                                                            <Box sx={{ paddingLeft: '2em', paddingRight: '2em' }}>
                                                                <Field name="company">
                                                                    {({ field, form }) => (
                                                                        <Autocomplete
                                                                            loading={loading}
                                                                            loadingText='Cargando...'
                                                                            sx={{ paddingBottom: '2em' }}
                                                                            {...field}
                                                                            id="company"
                                                                            name="company"
                                                                            placeholder="company"
                                                                            options={companies}
                                                                            getOptionLabel={option => option.name}
                                                                            onChange={(e, selectedCompany) =>
                                                                                form.setFieldValue('company', selectedCompany || null)
                                                                            }
                                                                            value={form.values.company}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    label="¿Con que empresa esta relacionada la denuncia?"
                                                                                    variant="outlined"
                                                                                    error={form.touched.company && Boolean(form.errors.company)}
                                                                                    helperText={form.touched.company && form.errors.company}
                                                                                    size="small"
                                                                                    inputProps={{
                                                                                        ...params.inputProps,
                                                                                        readOnly: true,
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <Field name="category">
                                                                    {({ field, form }) => (
                                                                        <Autocomplete
                                                                            loading={loading}
                                                                            loadingText='Cargando...'
                                                                            sx={{ paddingBottom: '2em' }}
                                                                            {...field}
                                                                            id="category"
                                                                            name="category"
                                                                            placeholder="category"
                                                                            options={categories}
                                                                            getOptionLabel={option => option.text}
                                                                            onChange={(e, selectedCategory) =>
                                                                                form.setFieldValue('category', selectedCategory || null)
                                                                            }
                                                                            value={form.values.category}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    label="¿En qué categoría encaja mejor su denuncia?"
                                                                                    variant="outlined"
                                                                                    error={form.touched.category && Boolean(form.errors.category)}
                                                                                    helperText={form.touched.category && form.errors.category}
                                                                                    size="small"
                                                                                    inputProps={{
                                                                                        ...params.inputProps,
                                                                                        readOnly: true,
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                                    )}
                                                                </Field>

                                                                <Field name="suspect">
                                                                    {({ field, form }) => {
                                                                        const selectedCategory = form.values.category?.id;
                                                                        const selectedCategoryData = categories.find(category => category.id === selectedCategory);
                                                                        const suspects = selectedCategoryData?.suspect || [];

                                                                        return (
                                                                            <Autocomplete
                                                                                loading={loading}
                                                                                loadingText='Cargando...'
                                                                                sx={{ paddingBottom: '2em' }}
                                                                                {...field}
                                                                                id="suspect"
                                                                                name="suspect"
                                                                                placeholder="suspect"
                                                                                options={suspects}
                                                                                getOptionLabel={option => option.text}
                                                                                onChange={(e, selectedSuspect) =>
                                                                                    form.setFieldValue('suspect', selectedSuspect || null)
                                                                                }
                                                                                value={form.values.suspect}
                                                                                renderInput={params => (
                                                                                    <TextField
                                                                                        {...params}
                                                                                        label="¿Sospecha de alguna de las siguientes personas?"
                                                                                        variant="outlined"
                                                                                        error={form.touched.suspect && Boolean(form.errors.suspect)}
                                                                                        helperText={form.touched.suspect && form.errors.suspect}
                                                                                        size="small"
                                                                                        inputProps={{
                                                                                            ...params.inputProps,
                                                                                            readOnly: true,
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                                noOptionsText="Seleccione una Categoria primero"
                                                                            />
                                                                        );
                                                                    }}
                                                                </Field>
                                                            </Box>
                                                        </WizardStep>

                                                        {/* Step 2 */}
                                                        <WizardStep
                                                            validationSchema={yup.object({
                                                                relationship: yup.object().required('Seleccione una relación'),
                                                                is_anonymous: yup.string().required('Seleccione una opción'),
                                                                name: yup.string()
                                                                    .max(255, 'El campo tiene un máximo de 255 carácteres')
                                                                    .when('is_anonymous', {
                                                                        is: (val) => val === '0',
                                                                        then: (schema) => schema.required('El nombre es obligatorio'),
                                                                    }),
                                                                email: yup.string()
                                                                    .email('Introduce un email correcto')
                                                                    .max(255, 'El campo tiene un máximo de 255 carácteres')
                                                                    .when('is_anonymous', {
                                                                        is: (val) => val === '0',
                                                                        then: (schema) => schema.required('El email es obligatorio'),
                                                                    }),
                                                                phone: yup.string()
                                                                    .matches(/^\d{9}$/, 'El número de teléfono debe contener 9 dígitos y solo números')
                                                                    .nullable(),
                                                            })}

                                                        >
                                                            <Box sx={{ paddingLeft: '2em', paddingRight: '2em' }}>
                                                                <Field name="relationship">
                                                                    {({ field, form }) => (
                                                                        <Autocomplete
                                                                            sx={{ paddingBottom: '2em' }}
                                                                            {...field}
                                                                            id="relationship"
                                                                            name="relationship"
                                                                            placeholder="relationship"
                                                                            options={relationships}
                                                                            getOptionLabel={option => option.text}
                                                                            onChange={(e, selectedRelationship) =>
                                                                                form.setFieldValue('relationship', selectedRelationship || null)
                                                                            }
                                                                            value={form.values.relationship}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    label="¿Qué relación tiene con la empresa denunciada?"
                                                                                    variant="outlined"
                                                                                    error={form.touched.relationship && Boolean(form.errors.relationship)}
                                                                                    helperText={form.touched.relationship && form.errors.relationship}
                                                                                    size="small"
                                                                                    inputProps={{
                                                                                        ...params.inputProps,
                                                                                        readOnly: true,
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                                    )}
                                                                </Field>

                                                                <Box sx={{ marginBottom: "1rem" }}>
                                                                    <Typography variant="body1" component="p">
                                                                        ¿Quiere comunicarlo de manera anonima?
                                                                    </Typography>
                                                                    <Field name="is_anonymous">
                                                                        {({ field, form }) => (
                                                                            <FormControl>
                                                                                <RadioGroup
                                                                                    name={field.name}
                                                                                    value={field.value}
                                                                                    onChange={(e, selectedIs_anonymous) => {
                                                                                        form.setFieldValue('is_anonymous', selectedIs_anonymous);
                                                                                    }}
                                                                                >
                                                                                    <FormControlLabel
                                                                                        value="0"
                                                                                        control={<Radio />}
                                                                                        label="No"
                                                                                    />
                                                                                    <FormControlLabel
                                                                                        value="1"
                                                                                        control={<Radio />}
                                                                                        label="Si"
                                                                                    />
                                                                                </RadioGroup>
                                                                            </FormControl>

                                                                        )}
                                                                    </Field>
                                                                </Box>

                                                                <>
                                                                    <Box>
                                                                        <Field name="name">
                                                                            {({ field, form }) => (
                                                                                form.values.is_anonymous === '0' ? (
                                                                                    <Box sx={{ marginBottom: "1rem" }}>
                                                                                        <TextField
                                                                                            {...field}
                                                                                            fullWidth
                                                                                            label="Nombre y apellidos"
                                                                                            error={form.touched.name && Boolean(form.errors.name)}
                                                                                            helperText={form.touched.name && form.errors.name}
                                                                                        />
                                                                                    </Box>
                                                                                ) : null
                                                                            )}
                                                                        </Field>

                                                                        <Field name="email">
                                                                            {({ field, form }) => (
                                                                                form.values.is_anonymous === '0' ? (
                                                                                    <Box sx={{ marginBottom: "1rem" }}>
                                                                                        <TextField
                                                                                            {...field}
                                                                                            fullWidth
                                                                                            label="Email"
                                                                                            error={form.touched.email && Boolean(form.errors.email)}
                                                                                            helperText={form.touched.email && form.errors.email}
                                                                                        />
                                                                                    </Box>
                                                                                ) : null
                                                                            )}
                                                                        </Field>

                                                                        <Field name="phone">
                                                                            {({ field, form }) => (
                                                                                form.values.is_anonymous === '0' ? (
                                                                                    <Box sx={{ marginBottom: "1rem" }}>

                                                                                        <TextField
                                                                                            {...field}
                                                                                            fullWidth
                                                                                            label="Nº de telefono"
                                                                                            error={form.touched.phone && Boolean(form.errors.phone)}
                                                                                            helperText={form.touched.phone && form.errors.phone}
                                                                                        />
                                                                                    </Box>
                                                                                ) : null
                                                                            )}
                                                                        </Field>
                                                                    </Box>

                                                                </>
                                                                <Typography variant="body1" component="p" color="text.secondary" sx={{ fontSize: '0.9rem', textAlign: 'justify' }}>
                                                                    Este canal es confidencial. Sus datos personales no serán compartidos. Si alguien de la organización necesita conocer sus datos, lo solicitará a través del canal y solo si ud. lo autoriza los podrá ver.
                                                                </Typography>
                                                            </Box>
                                                        </WizardStep>

                                                        {/* Step 3 */}
                                                        <WizardStep
                                                            validationSchema={yup.object({
                                                                location: yup.string()
                                                                    .nullable()
                                                                    .min(5, 'El campo tiene un mínimo de 5 carácteres')
                                                                    .max(255, 'El campo tiene un máximo de 255 carácteres'),
                                                                incident: yup.string()
                                                                    .required('Campo obligatorio')
                                                                    .min(5, 'Es obligatiorio escribir un minimo de 5 carácteres')
                                                                    .max(65535, 'El campo tiene un máximo de 65535 carácteres'),
                                                                notifiedManagment: yup.string().required('Seleccione una opción'),
                                                                complaint_subject_person: yup.string()
                                                                    .nullable()
                                                                    .max(255, 'El campo tiene un máximo de 255 carácteres'),
                                                                witness: yup.string()
                                                                    .nullable()
                                                                    .max(255, 'El campo tiene un máximo de 255 carácteres'),
                                                                files: yup.mixed().nullable(),
                                                            })}
                                                        >
                                                            <Box sx={{ paddingLeft: '2em', paddingRight: '2em' }}>
                                                                <Box sx={{ marginBottom: "1rem" }}>
                                                                    <Typography variant="body1" component="p">
                                                                        ¿Ha comentado este hecho con algún miembro de la dirección?
                                                                    </Typography>
                                                                    <Field name="notifiedManagment">
                                                                        {({ field, form }) => (
                                                                            <RadioGroup
                                                                                name={field.name}
                                                                                value={field.value}
                                                                                onChange={(e, selectedNotifiedManagment) => {
                                                                                    form.setFieldValue('notifiedManagment', selectedNotifiedManagment || null);
                                                                                }}
                                                                            >
                                                                                <FormControlLabel
                                                                                    value="si"
                                                                                    control={<Radio />}
                                                                                    label="Si"
                                                                                />
                                                                                <FormControlLabel
                                                                                    value="no"
                                                                                    control={<Radio />}
                                                                                    label="No"
                                                                                />
                                                                                <FormControlLabel
                                                                                    value="no recuerdo"
                                                                                    control={<Radio />}
                                                                                    label="No recuerdo"
                                                                                />
                                                                            </RadioGroup>

                                                                        )}
                                                                    </Field>
                                                                    <ErrorMessage
                                                                        className="error"
                                                                        component="div"
                                                                        name="notifiedManagment"
                                                                        style={{ color: 'red' }}
                                                                    />
                                                                </Box>

                                                                <Field name="location">
                                                                    {({ field, form }) => (
                                                                        <Box sx={{ marginBottom: "1rem" }}>
                                                                            <TextField
                                                                                {...field}
                                                                                style={{ width: '100%' }}
                                                                                label="¿Dónde sucedió?"
                                                                                error={form.touched.location && Boolean(form.errors.location)}
                                                                                helperText={form.touched.location && form.errors.location}
                                                                            />
                                                                        </Box>
                                                                    )}
                                                                </Field>


                                                                <Field name="complaint_subject_person">
                                                                    {({ field, form }) => (
                                                                        <Box sx={{ marginBottom: "1rem" }}>
                                                                            <TextField
                                                                                {...field}
                                                                                style={{ width: '100%' }}
                                                                                label="Persona/s especifica relacionada a la que denuncia"
                                                                                error={form.touched.complaint_subject_person && Boolean(form.errors.complaint_subject_person)}
                                                                                helperText={form.touched.complaint_subject_person && form.errors.complaint_subject_person}
                                                                            />
                                                                        </Box>
                                                                    )}
                                                                </Field>

                                                                <Field name="witness">
                                                                    {({ field, form }) => (
                                                                        <Box sx={{ marginBottom: "1rem" }}>
                                                                            <Box sx={{ marginBottom: "1rem" }}>
                                                                                <TextField
                                                                                    {...field}
                                                                                    style={{ width: '100%' }}
                                                                                    label="Testigo/s de lo ocurrido"
                                                                                    error={form.touched.witness && Boolean(form.errors.witness)}
                                                                                    helperText={form.touched.witness && form.errors.witness}
                                                                                />
                                                                            </Box>
                                                                            {/*<DynamicList listData={witnessListData} onListDataChange={handleSaveWitnessListData} />*/}
                                                                        </Box>
                                                                    )}
                                                                </Field>

                                                                <Typography variant="body1" component="p">
                                                                    Indíquenos el hecho que desea denunciar en detalle:
                                                                </Typography>
                                                                <Field name="incident">
                                                                    {({ field, form }) => (
                                                                        <Box sx={{ marginBottom: "1rem" }}>
                                                                            <TextareaAutosize
                                                                                {...field}
                                                                                minRows={8}
                                                                                style={{ width: '100%', resize: 'none' }}
                                                                            />
                                                                            <ErrorMessage className="error" component="div" name="incident" style={{ color: 'red' }} />
                                                                        </Box>
                                                                    )}
                                                                </Field>
                                                                <Field name="files">
                                                                    {({ field, form }) => (
                                                                        <Box sx={{ marginBottom: "1rem" }}>
                                                                            <Box sx={{ marginBottom: "1rem" }}>
                                                                                <input
                                                                                    multiple
                                                                                    accept='image/png, image/jpeg'
                                                                                    id='files'
                                                                                    type='file'
                                                                                    onChange={(event) => {
                                                                                        form.setFieldValue('files', event.currentTarget.files);
                                                                                    }}
                                                                                />
                                                                            </Box>
                                                                            {/*<DynamicList listData={witnessListData} onListDataChange={handleSaveWitnessListData} />*/}
                                                                        </Box>
                                                                    )}
                                                                </Field>
                                                            </Box>
                                                        </WizardStep>

                                                        {/* Step 4 */}
                                                        <WizardStep
                                                            validationSchema={yup.object({
                                                                acceptTermsOfUse: yup.bool()
                                                                    .oneOf([true], 'Debe aceptar los términos de uso'),
                                                                acceptDataProtection: yup
                                                                    .bool()
                                                                    .oneOf([true], 'Debe aceptar los términos de uso'),
                                                                access_key: yup
                                                                    .string()
                                                                    .required('Establezca una contraseña')
                                                                    .matches(
                                                                        /^(?=.*[A-Z])/,
                                                                        'La contraseña debe contener al menos una mayúscula'
                                                                    )
                                                                    .matches(
                                                                        /^(?=.*[-_!@#$%^&*()+=\\[\]{};':"\\|,.<>\\/?])[\w\d-!@#$%^&*()+=\\[\]{};':"\\|,.<>\\/?]*$/,
                                                                        'La contraseña debe tener al menos un carácter especial'
                                                                    )
                                                                    .min(8, 'La contraseña debe tener al menos 8 caracteres de longitud')
                                                                ,

                                                                confirmPassword: yup
                                                                    .string()
                                                                    .required('Confirme la contraseña')
                                                                    .oneOf([yup.ref('access_key')], 'Las contraseñas no coinciden'),
                                                            })}
                                                        >

                                                            <Box sx={{ paddingLeft: '2em', paddingRight: '2em' }}>
                                                                <Field name="access_key">
                                                                    {({ field, form }) => (
                                                                        <Box sx={{ marginBottom: "1rem" }}>
                                                                            <TextField
                                                                                type='password'
                                                                                {...field}
                                                                                style={{ width: '50%' }}
                                                                                label="Contraseña"
                                                                                error={form.touched.access_key && Boolean(form.errors.access_key)}
                                                                                helperText={form.touched.access_key && form.errors.access_key}
                                                                                disabled={submittingForm}
                                                                            />
                                                                        </Box>
                                                                    )}
                                                                </Field>

                                                                <Field name="confirmPassword">
                                                                    {({ field, form }) => (
                                                                        <Box sx={{ marginBottom: "1rem" }}>
                                                                            <TextField
                                                                                type='password'
                                                                                {...field}
                                                                                style={{ width: '50%' }}
                                                                                label="Confirmar contraseña"
                                                                                error={form.touched.confirmPassword && Boolean(form.errors.confirmPassword)}
                                                                                helperText={form.touched.confirmPassword && form.errors.confirmPassword}
                                                                                disabled={submittingForm}
                                                                            />
                                                                        </Box>
                                                                    )}
                                                                </Field>

                                                                <Box sx={{ paddingBottom: '1em', paddingTop: '1em' }}>
                                                                    <Field name="acceptDataProtection">
                                                                        {({ field, form }) => (
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        {...field}
                                                                                        checked={form.values.acceptDataProtection}
                                                                                        onChange={form.handleChange}
                                                                                        disabled={submittingForm}
                                                                                    />
                                                                                }
                                                                                label={
                                                                                    <>
                                                                                        He leído el Aviso sobre protección de datos y consentimiento y acepto el procesamiento de mis datos personales de conformidad con lo dispuesto en dicho aviso.
                                                                                    </>
                                                                                }
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <ErrorMessage className="error" component="div" name="acceptDataProtection" style={{ color: 'red' }} />
                                                                </Box>
                                                                <DataAccordion title="Aviso sobre protección de datos y consentimiento" content={data.payload.dataProtectWarning} />


                                                                <Box sx={{ paddingBottom: '1em', paddingTop: '1em' }}>
                                                                    <Field name="acceptTermsOfUse">
                                                                        {({ field, form }) => (
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        {...field}
                                                                                        checked={form.values.acceptTermsOfUse}
                                                                                        onChange={form.handleChange}
                                                                                        disabled={submittingForm}
                                                                                    />
                                                                                }
                                                                                label={
                                                                                    <>
                                                                                        Acepto los términos de uso
                                                                                    </>
                                                                                }
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <ErrorMessage className="error" component="div" name="acceptTermsOfUse" style={{ color: 'red' }} />

                                                                </Box>
                                                                <DataAccordion title="Terminos de uso" content={data.payload.termOfUse} />



                                                            </Box>
                                                        </WizardStep>
                                                    </Wizard>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                </>
                            }
                        </>
                    )
                    }
                </>
            )}
        </>
    );
}

export default CreateIncident;
