import CancelIcon from '@mui/icons-material/PictureAsPdf';
import { Typography, Box, Button } from '@mui/material';

// Definir el componente FilesCard
const FilesCard = (props) => {
    // Extraer la propiedad 'communication' del objeto 'props'
    const { communication } = props;

    // Función para descargar el informe de creación de denuncia en formato PDF
    const descargaInicioPdf = () => {
        const blob = communication.generatedFiles[0].data
        const filename = communication.generatedFiles[0].name
        const downloadUrl = `${blob}`;
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = filename;
        link.click();
    }

    // Función preparada para descargar el informe de finalización de denuncia en PDF
    // const descargaFinPdf = () => {
    //     const blob = communication.generatedFiles[1].data
    //     const filename = communication.generatedFiles[1].name
    //     const downloadUrl = `${blob}`;
    //     const link = document.createElement('a');
    //     link.href = downloadUrl;
    //     link.download = filename;
    //     link.click();
    // }

    // Renderizar el contenido del componente
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <Typography variant="h6" component="h6" >
                    Informes de la denuncia
                </Typography>
                <Typography variant="body1" component="p" sx={{ paddingBottom: '1em', textAlign: 'justify' }}>
                    Para su seguridad la plataforma del canal de denuncias genera un informe en el momento de realizar la denuncia. Este informe se puede descargar y puede servir en el caso que lo necesite más adelante. Es la garantía de que se ha presentado una denuncia a través de la plataforma.
                </Typography>
            </Box>
            <Box sx={{ paddingLeft: '3em', paddingTop: '1em' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" component="h6" >
                        Informe de la denuncia:
                    </Typography>
                    {/* Botón para descargar el informe */}
                    <Button
                        startIcon={<CancelIcon />}
                        sx={{ color: 'white', ml: '2em' }}
                        onClick={() => descargaInicioPdf()}
                        variant="contained"
                        color="primary"
                    >
                        Descargar
                    </Button>
                </Box>
            </Box>
        </>
    );
}

// Exportar el componente FilesCard por defecto
export default FilesCard;
