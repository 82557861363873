import { Box, Typography } from '@mui/material';

// Definir el componente DetailsCard
const DetailsCard = (props) => {
    // Extraer la propiedad 'communication' del objeto 'props'
    const { communication } = props;

    // Renderizar el contenido del componente
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <Typography variant="h6" component="h6" >
                    Detalles de la denuncia
                </Typography>
                <Typography variant="body1" component="p" sx={{ paddingBottom: '1em', textAlign: 'justify' }}>
                    Aquí se muestran toda la información acerca de la denuncia.
                </Typography>
            </Box>
            <Box sx={{ paddingLeft: '3em', paddingTop: '1em' }}>
                <Typography variant="h6" component="h6" >
                    Empresa:
                </Typography>
                <Typography variant="body1" component="p" sx={{ paddingBottom: '1em' }}>
                    {communication.company}
                </Typography>

                <Typography variant="h6" component="h6" >
                    Categoria de la denuncia:
                </Typography>
                <Typography variant="body1" component="p" sx={{ paddingBottom: '1em' }}>
                    {communication.category}
                </Typography>

                <Typography variant="h6" component="h6" >
                    ¿Sospecha de alguna de las siguientes personas?:
                </Typography>
                <Typography variant="body1" component="p" sx={{ paddingBottom: '1em' }}>
                    {communication.suspect}
                </Typography>

                {/* Renderizar la siguiente sección solo si la propiedad 'happend' está definida en 'communication' */}
                {communication.happend && (
                    <>
                        <Typography variant="h6" component="h6">
                            ¿Donde sucedió?:
                        </Typography>
                        <Typography variant="body1" component="p" sx={{ paddingBottom: '1em' }}>
                            {communication.happend}
                        </Typography>
                    </>
                )}

                <Typography variant="h6" component="h6" >
                    Se lo comunicó a alguien relacionado:
                </Typography>
                <Typography variant="body1" component="p" sx={{ paddingBottom: '1em' }}>
                    {communication.commented}
                </Typography>

                {/* Renderizar la siguiente sección solo si la propiedad 'complaint_subject_person' está definida en 'communication' */}
                {communication.complaint_subject_person && (
                    <>
                        <Typography variant="h6" component="h6" >
                            Sujeto/s a quien va dirigida la denuncia:
                        </Typography>
                        <Typography variant="body1" component="p" sx={{ paddingBottom: '1em' }}>
                            {communication.complaint_subject_person.replace(/"/g, '')}
                        </Typography>
                    </>
                )}

                {/* Renderizar la siguiente sección solo si la propiedad 'witness' está definida en 'communication' */}
                {communication.witness && (
                    <>
                        <Typography variant="h6" component="h6" >
                            Testigo/s del suceso:
                        </Typography>
                        <Typography variant="body1" component="p" sx={{ paddingBottom: '1em' }}>
                            {communication.witness.replace(/"/g, '')}
                        </Typography>
                    </>
                )}

                <Typography variant="h6" component="h6" >
                    Detalle de la denuncia:
                </Typography>
                <Typography variant="body1" component="p" sx={{ paddingBottom: '1em' }}>
                    {communication.description}
                </Typography>
            </Box>
        </>
    );
}

// Exportar el componente DetailsCard por defecto
export default DetailsCard;
