import { useState, useEffect } from 'react';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import { HeadProvider, Title } from 'react-head';

import QuestionCard from '../components/faq/QuestionCard';
import useFetch from '../hooks/useFetch';

const Faq = () => {
  const { data, loading, errorFetch } = useFetch('faqs'); // Utilizamos el hook useFetch y almacenamos los valores retornados en las variables data, loading y errorFetch
  const [questionCardItems, setQuestionCardItems] = useState([]); // Utilizamos el hook useState para crear una variable de estado questionCardItems y su función setter setQuestionCardItems, inicializada con un arreglo vacío

  useEffect(() => {
    if (data && data.payload && data.payload.faqs) {
      setQuestionCardItems(data.payload.faqs); // Utilizamos el hook useEffect para actualizar questionCardItems cuando los datos (data) cambien y cumplan ciertas condiciones
    }
  }, [data]);

  return (
    <>
      <HeadProvider>
        <Title>Preguntas Frecuentes</Title> {/* Establecemos el título de la página utilizando el componente Title */}
      </HeadProvider>

      {/* Sección de encabezado */}
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingBottom: '2em' }}>
        <Typography variant='h4' component='h4' >
          Preguntas Frecuentes
        </Typography>
        <Typography variant="body1" component="p">
          Aquí puede consultar las preguntas frecuentes del canal de denuncias de grupo Dimoba
        </Typography>
      </Box>

      {/* Barra de progreso */}
      {loading && <LinearProgress />}

      {/* Manejo de error o visualización de las preguntas frecuentes */}
      {
        errorFetch ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <Typography variant="body1" component="p">
              Ha ocurrido un error en la carga de datos, pruebe más tarde.
            </Typography>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            {/* Grid para mostrar las preguntas frecuentes */}
            <Grid
              container
              spacing={{ xs: 2, sm: 2 }}
              columns={{ xs: 6, sm: 6, md: 12 }}
              justifyContent="center"
              alignItems="stretch"
              sx={{ paddingTop: '3em', flexWrap: 'wrap' }}
            >
              {questionCardItems.map((data) => (
                <Grid item xs={12} sm={6} md={6} key={data.id}>
                  <Box sx={{  height: '100%' }}>
                    {/* Renderización del componente QuestionCard */}
                    <QuestionCard
                      id={data.id}
                      title={data.title}
                      text={data.text}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        )
      }

    </>
  );
};

export default Faq;
