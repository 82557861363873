import { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import parse from 'react-html-parser';

const DataAccordion = (props) => {
    const { title, content } = props;
    const [expanded, setExpanded] = useState(false);

    const handleClick = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    //Componente expansible que contiene como funcion contener un texto como el de tems of use, en el formulario CreateIncident

    return (
        <Accordion expanded={expanded === 'panel1'} onChange={handleClick('panel1')}>
            <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                id="panel1bh-header"
            >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    {parse(content)}
                </Typography>
            </AccordionDetails>
        </Accordion>
    );

}

export default DataAccordion;
