import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import parse from 'react-html-parser';

// Definir el componente PrivTermsCard
const PrivTermsCard = (props) => {
  // Extraer la propiedad 'communication' del objeto 'props'
  const { communication } = props;

  // Estado para controlar el panel expandido
  const [expanded, setExpanded] = useState(false);

  // Función para manejar el cambio de panel expandido
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Renderizar el contenido del componente
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Typography variant="h6" component="h6" >
          Políticas y términos
        </Typography>
        <Typography variant="body1" component="p" sx={{ paddingBottom: '1em', textAlign: 'justify' }}>
          Aquí se pueden consultar los términos de uso y la política de privacidad al momento de la creación de la denuncia.
        </Typography>
      </Box>
      {/* Panel para la política de privacidad */}
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ marginTop: '1em' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Política de privacidad</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            {/* Utilizar 'react-html-parser' para mostrar el contenido HTML */}
            {parse(communication.privacyPolicy)}
          </Box>
        </AccordionDetails>
      </Accordion>
      {/* Panel para los términos de uso */}
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="panel2bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Términos de uso</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            {/* Utilizar 'react-html-parser' para mostrar el contenido HTML */}
            {parse(communication.termOfUse)}
          </Box>
        </AccordionDetails>
      </Accordion>
      {/* Panel para el aviso de protección de datos */}
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="panel3bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Aviso de protección de datos</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            {/* Utilizar 'react-html-parser' para mostrar el contenido HTML */}
            {parse(communication.dataProtectWarning)}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

// Exportar el componente PrivTermsCard
export default PrivTermsCard;
