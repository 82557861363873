import { Box, Grid, Typography } from '@mui/material';
import { HeadProvider, Title } from 'react-head';

import LinkCard from '../components/home/LinkCard';

const linkCardItems = [
  { id: 1, title: 'Comunicar problema', description: 'Comuníquenos cualquier actuación que en su opinión, sea contraria al Código de Ética y Conducta.', to: '/crear-incidencia', buttonText: 'Comunicar problema' },
  { id: 2, title: 'Consultar estado de incidencia', description: 'Si ha realizado una comunicación, indique el identificador de la misma y consulta.', to: '/consultar-incidencia', buttonText: 'Consultar estado incidencia' },
  { id: 3, title: 'Preguntas frecuentes', description: 'Tiene dudas sobre este canal de denuncias, aquí encontrará las preguntas más frecuentes.', to: '/faq', buttonText: 'Accede' },
];

const Home = () => {
  return (
    <>
      <HeadProvider>
        <Title>Canal de denuncias</Title> {/* Establecemos el título de la página utilizando el componente Title */}
      </HeadProvider>

      {/* Sección de encabezado */}
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Typography variant='h4' component='h4' sx={{ paddingBottom: '1em' }}>
          Canal de denuncias de Dimoba Corporación
        </Typography>
        <Typography variant='body1' component='p' sx={{ textAlign: 'justify' }}>
          Si ha detectado alguna actuación negligente en el ámbito de la organización y tienes pruebas, puede denunciarlo desde este <strong>Formulario Telemático</strong> o llamando telefónicamente al <strong>950 271 526</strong> de forma totalmente anónima y segura.
        </Typography>
        <Typography variant='body1' component='p' sx={{ textAlign: 'justify', width:'100%', padding:'2%' }}>
          La utilización de este canal es sólo si se tiene constancia de la comisión de un delito o infracción administrativa grave.
        </Typography>
      </Box>

      {/* Grid para mostrar las tarjetas de enlace */}
      <Grid
        container
        spacing={{ xs: 2, sm: 2 }}
        columns={{ xs: 4, sm: 4, md: 12 }}
        justifyContent="center"
        alignItems="stretch"
        sx={{ paddingTop: '3em', flexWrap: 'wrap', width: '100%' }} // Agregamos width: '100%' para expandir el Grid al ancho del padre
      >
        {linkCardItems.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            <Box sx={{ paddingLeft: '2em', paddingRight: '1em', height: '100%' }}>
              {/* Renderización del componente LinkCard */}
              <LinkCard
                id={item.id}
                title={item.title}
                description={item.description}
                to={item.to}
                buttonText={item.buttonText}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Home;
