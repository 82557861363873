import { useState } from 'react';
import { AppBar, Box, Tab, Tabs, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';

import DetailsCard from './DetailsCard';
import ConnectionCard from './ConnectionCard';
import PrivTermsCard from './PrivTermsCard';
import FilesCard from './FilesCard';
import ImagesCard from './ImagesCard';
import FinishCard from './FinishCard';

// Definir los datos de las pestañas
const tabsData = [
  //Se ordenan segun su id
  //{ id: 0, title: 'Actividad' },
  { id: 0, title: 'Detalle' },
  { id: 1, title: 'Informes' },
  //  { id: 3, title: 'Permisos' },
  { id: 2, title: 'Conexiones' },
  { id: 3, title: 'Resolución' },
  { id: 4, title: 'Políticas y términos' },
  { id: 5, title: 'Imagenes' },
];

// Definir el componente TabsNavBarShowIncident
const TabsNavBarShowIncident = (props) => {
  const { communication } = props;

  // Definir el componente TabPanel
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </Box>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  // Definir la función a11yProps
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
        >
          {tabsData.map((item) => {
            if (item.id === 5 && communication.uploadedFiles.length < 1) { // Hide images tab when communication does not include any uploaded files
              return null
            } else {
              return <Tab key={item.id} label={item.title} {...a11yProps(item.id)} />
            }
          })}
        </Tabs>
      </AppBar>
      {/* Es para el efecto visual al cambiar entre tabs */}
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {tabsData.map((item) => (
          <TabPanel key={item.id} value={value} index={item.id} dir={theme.direction}>
            {(() => {
              //Un case por cada objeto/id en tabsData
              switch (item.id) {
                /* case 0:
                   return (
                     <>
                       <ActivityCard key={item.id}
                         activityList={activityList}
                       />
                     </>
                   );
                   */

                case 0:
                  return (
                    <>
                      <DetailsCard key={item.id}
                        communication={communication}
                      />
                    </>
                  );

                case 1:
                  return <FilesCard key={item.id}
                    communication={communication} />;
                case 2:
                  return (
                    <>
                      <ConnectionCard key={item.id}
                        communication={communication}
                      />
                    </>
                  );
                case 3:
                  return (
                    <>
                      <FinishCard key={item.id}
                        communication={communication}
                      />
                    </>
                  );
                case 4:
                  return (
                    <>
                      <PrivTermsCard key={item.id}
                        communication={communication}
                      />
                    </>
                  );
                case 5:
                  if (communication.uploadedFiles.length > 0) {
                    return (
                      <>
                        <ImagesCard key={item.id}
                          communication={communication}
                        />
                      </>
                    );
                  } else {
                    return null;
                  }
                default:
                  return null;
              }
            })()}
          </TabPanel>
        ))}
      </SwipeableViews>
    </Box>
  );
};

export default TabsNavBarShowIncident;
