import { Card, CardContent, Typography } from '@mui/material';

import TabsNavBarShowIncident from './TabsNavBarShowIncident';

// Definir el componente ShowIncidentCard
const ShowIncidentCard = (props) => {
  // Extraer la propiedad 'communication' del objeto 'props'
  const { communication } = props;
  const id = communication.communication_token;

  // Renderizar el contenido del componente
  return (
    <>
      <Card key={id} sx={{ maxWidth: '100%', height: '100%', display: 'flex', flexDirection: 'column', minHeight: '650px' }}>
        <CardContent>
          <Typography variant="body1" component="p" color="text.secondary" sx={{ fontSize: '0.9rem', textAlign: 'justify' }}>
            Código de denuncia #: {id}
          </Typography>
        </CardContent>
        <TabsNavBarShowIncident
          communication={communication}
        />
      </Card>
    </>
  );
}

// Exportar el componente ShowIncidentCard
export default ShowIncidentCard;
