import { ThemeProvider } from '@emotion/react';
import { Box } from '@mui/material';
import { RouterProvider } from 'react-router-dom';

import router from './router';
import theme from './theme';

function App() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', margin: 0 }}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </Box>
  );
}

export default App;
