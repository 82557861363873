import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

// Definir el componente ConnectionCard
const ConnectionCard = (props) => {
    // Extraer la propiedad 'communication' del objeto 'props'
    const { communication } = props;
    // Extraer las conexiones de 'communication'
    const connections = communication.connections;

    // Renderizar el contenido del componente
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <Typography variant="h6" component="h6" >
                    Conexiones e intentos de conexión realizados a esta denuncia
                </Typography>
                <Typography variant="body1" component="p" sx={{ paddingBottom: '1em', textAlign: 'justify' }}>
                    Con el objetivo de garantizar la transparencia, Dimoba Corporación le informa de todos los accesos o intentos de acceso a su denuncia. Recuerde que el código de denuncia es secreto y solo lo conocen usted o las personas con las que lo ha compartido.
                </Typography>
            </Box>
            {/* Componente TableContainer de Material-UI para contener la tabla */}
            <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                {/* Componente Table de Material-UI para representar la tabla */}
                <Table sx={{ minWidth: 650 }}>
                    {/* Componente TableHead de Material-UI para representar la cabecera de la tabla */}
                    <TableHead>
                        {/* Componente TableRow de Material-UI para representar una fila de la cabecera */}
                        <TableRow>
                            <TableCell>Estado del intento</TableCell>
                            <TableCell>Fecha</TableCell>
                        </TableRow>
                    </TableHead>
                    {/* Componente TableBody de Material-UI para representar el cuerpo de la tabla */}
                    <TableBody>
                        {/* Iterar sobre las conexiones en orden inverso */}
                        {connections.reverse().map((connection) => (
                            <TableRow
                                key={connection.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {/* Mostrar el estado del intento según el valor de 'connection.status' */}
                                {connection.status !== '200' ? (
                                    <TableCell>Intento fallido</TableCell>
                                ) : (
                                    <TableCell>Intento satisfactorio</TableCell>
                                )}
                                {/* Mostrar la fecha formateada utilizando moment.js */}
                                <TableCell>{connection.timestamp}</TableCell>
                            </TableRow>
                        )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

// Exportar el componente ConnectionCard por defecto
export default ConnectionCard;
