import { useEffect, useState } from "react";
import { apiBaseUrl, apiToken } from "../config/backend"; // Importamos las constantes apiBaseUrl y apiToken desde "../config/backend"

function useFetch(route, type = 'GET') {
    const [data, setData] = useState([]); // Estado para almacenar los datos de la respuesta de la petición
    const [loading, setLoading] = useState(true); // Estado para indicar si la petición está en curso
    const [errorFetch, setErrorFetch] = useState(false); // Estado para indicar si ocurrió un error en la petición

    /*
        Hook personalizado de react para realizar peticiones fetch.
        Este hook no está implementado en Formik debido a las siguientes razones:

        - Los hooks solo pueden ser llamados desde componentes funcionales.
        - Asegúrate de que tu componente está implementado como una función y no como una clase.
    */
    useEffect(() => {
        var url = apiBaseUrl + route; // Construcción de la URL completa para la petición

        const fetchData = async () => {
            const response = await fetch(url, { // Realizamos la petición fetch a la URL especificada
                method: type,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + apiToken,
                },
            });
            return await response.json(); // Devolvemos los datos de la respuesta como objeto JSON
        }

        fetchData()
            .then((response) => setData(response)) // Actualizamos el estado con los datos de la respuesta
            .catch(() => setErrorFetch(true)) // Actualizamos el estado para indicar que ocurrió un error en la petición
            .finally(() => setLoading(false)); // Actualizamos el estado para indicar que la petición ha finalizado

    }, [route, type]);

    return { data, loading, errorFetch }; // Devolvemos el estado actualizado para que pueda ser utilizado en otros componentes
}

export default useFetch;
