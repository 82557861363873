import { Box, Container, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

import dimobaServicios from '../assets/dimobaServicios.png';
import dimobaCEE from '../assets/dimobaCEE.png';
import dimobaSuministros from '../assets/dimobaSuministros.png';
import grupocontrol from '../assets/grupocontrol.png';
import idi from '../assets/idi.png';
import traconsa from '../assets/traconsa.png';
import powered_by_idi from '../assets/powered_by_idi.png';

function Footer() {
  let onXsScreen = useMediaQuery(useTheme().breakpoints.down(525))
  return (
    <Box
      sx={{
        width: '100%',
        marginTop: 'auto',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Container maxWidth="xl">
          <Grid container spacing={2} alignItems='center' justifyContent='space-between'>
            <Grid item>
              <IconButton
                component="a"
                href="https://dimobaservicios.com/"
                target="_blank"
                sx={{ display: 'flex', mr: 1 }}
              >
                <img src={dimobaServicios} alt="Logo" style={{ height: '100%', width: 'auto' }} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                component="a"
                href="https://www.traconsa.com/"
                target="_blank"
                sx={{ display: 'flex', mr: 1 }}
              >
                <img src={traconsa} alt="Logo" style={{ height: '100%', width: 'auto' }} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                component="a"
                href="https://www.dimobasuministros.com/"
                target="_blank"
                sx={{ display: 'flex', mr: 1 }}
              >
                <img src={dimobaSuministros} alt="Logo" style={{ height: '100%', width: 'auto' }} />
              </IconButton>
            </Grid>
            <Grid item>
              <img src={dimobaCEE} alt="Logo" style={{ display: 'flex', mr: 1, height: '100%', width: 'auto' }} />
            </Grid>
            <Grid item>
              <IconButton
                component="a"
                href="https://www.grupocontrol.com/"
                target="_blank"
                sx={{ display: 'flex', mr: 1 }}
              >
                <img src={grupocontrol} alt="Logo" style={{ height: '100%', width: 'auto' }} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                component="a"
                href="https://idi28.com/"
                target="_blank"
                sx={{ display: 'flex', mr: 1 }}
              >
                <img src={idi} alt="Logo" style={{ height: '100%', width: 'auto' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: 'primary.main', width: '100%' }}>
        <Container maxWidth="xl">
          <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexDirection: 'row' }}>
            <Grid container justifyContent='start' alignItems='center'>
              <Grid item>
                <Typography color="background2.main" component={Link} variant="subtitle1" to='/terminos-de-uso'>
                  Términos de uso
                </Typography>
              </Grid>
              <Grid>
                <Typography color="background2.main" component={Link} variant="subtitle1" to='/politica-de-privacidad' sx={{ paddingLeft: onXsScreen ? '0' : '1em' }}>
                  Política de privacidad
                </Typography>
              </Grid>
            </Grid>
            <IconButton
              component="a"
              href="https://idi28.com/"
              target="_blank"
              sx={{ display: 'flex', mr: 1 }}
            >
              <img src={powered_by_idi} alt="Logo" style={{ height: '100%', width: 'auto' }} />
            </IconButton>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default Footer;
